import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "Is Java pass by reference or pass by value?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Java is `}<strong parentName="p">{`always`}</strong>{` pass by value.`}</p>
    <p>{`It means the argument is copied to the parameter variable, so that the method
operates on the copy. When the value of an object is passed, the `}<strong parentName="p">{`reference`}</strong>{`
to it is passed. If a reference points to a mutable object like an `}<inlineCode parentName="p">{`ArrayList`}</inlineCode>{`
and gets changed inside a method, the originally passed variable is also
affected. Since passing an object as argument also means that the reference to
the object is copied. As a result, both variables(original and parameter) refer
to the same object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class PassByPrimitive {

  public static void main(String[] args) {
    int n = 1;
    increaseAndMultiply(n);
    System.out.println(n); // still 1
  }

  public static void increaseAndMultiply(int n) {
    n++;
    System.out.println(n * n); // 4
  }

}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.util.ArrayList;
import java.util.List;

public class PassByObject {

  public static void main(String[] args) {
    var list = new ArrayList<>(List.of("foo", "bar", "foobar"));
    mutate(list);
    System.out.println(list); // prints [foo, bar]
  }

  // object reference is passed by
  public static void mutate(List<String> list) {
    list.remove("foobar");
  }

}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      