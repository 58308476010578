import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "Describe and compare fail-fast and fail-safe iterators."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`fail-fast`}</strong>{`: Operates directly on the collection itself. Whenever the
collection is modified while iterating it throws a
`}<inlineCode parentName="p">{`ConcurrentModificationException`}</inlineCode>{`. (e.g. `}<inlineCode parentName="p">{`ArrayList`}</inlineCode>{`, `}<inlineCode parentName="p">{`HashSet`}</inlineCode>{`, `}<inlineCode parentName="p">{`HashMap`}</inlineCode>{`)`}</p>
    <p><strong parentName="p">{`fail-save`}</strong>{`: Operates on a cloned copy of the collection. Does not throw an
exception when it gets modified while iterating. (e.g. `}<inlineCode parentName="p">{`ConcurrentHashMap`}</inlineCode>{`,
`}<inlineCode parentName="p">{`CopyOnWriteArrayList`}</inlineCode>{`)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      