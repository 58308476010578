import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is the Java class loader? List and explain the purpose of the three types of class loaders."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Java `}<inlineCode parentName="p">{`ClassLoader`}</inlineCode>{` is part of the JRE and is used to load classes at runtime
on demand (lazy-loading) into the JVM. These classes can be loaded from a local
or remote file system or even from the web. When the JVM is started `}<strong parentName="p">{`3`}</strong>{` kinds
of `}<inlineCode parentName="p">{`ClassLoader`}</inlineCode>{` are used.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Bootstrap class loader`}</strong>{` - The JVM built-in class loader, which defines the
classes in a handful of critical modules, such as `}<inlineCode parentName="p">{`java.base`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Platform class loader`}</strong>{` - All classes in the Java SE Platform are
guaranteed to be visible through the platform class loader. In addition, the
classes in modules that are standardized under the Java Community Process but
not part of the Java SE Platform are guaranteed to be visible through the
platform class loader. (e.g. `}<inlineCode parentName="p">{`java.net.http`}</inlineCode>{`)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`System class loader`}</strong>{`, also known as `}<strong parentName="p">{`application class loader`}</strong>{`, that
defines classes on the application class path and module path. It is the
default loader for classes in modules that are neither Java SE nor JDK
modules. The platform class loader is a parent or ancestor of it, so it can
load platform classes by delegating to its parent.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      