import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is a deadlock?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A condition that occurs when two processes waiting for the other to complete,
before they proceed. As a result, both processes wait endlessly.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      