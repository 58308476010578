import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is unboxing and autoboxing in java?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Autoboxing is the automatic conversion the java compiler makes between the
primitive types and their corresponding object wrapper classes. For example,
converting an `}<inlineCode parentName="p">{`int`}</inlineCode>{` to `}<inlineCode parentName="p">{`Integer`}</inlineCode>{` or a `}<inlineCode parentName="p">{`double`}</inlineCode>{` to `}<inlineCode parentName="p">{`Double`}</inlineCode>{`. If the conversion
goes the other way, its called unboxing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      