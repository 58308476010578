import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is a final class? What is a final method?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A `}<inlineCode parentName="p">{`final`}</inlineCode>{` class `}<strong parentName="p">{`cannot`}</strong>{` be extended.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`final`}</inlineCode>{` method `}<strong parentName="p">{`cannot`}</strong>{` be overridden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      