import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "Where would you use LinkedList and where an ArrayList?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><inlineCode parentName="p">{`ArrayList`}</inlineCode>{` is to be preferred, when you have a lot of random access via
`}<inlineCode parentName="p">{`List.get(n)`}</inlineCode>{`. Even more, when elements are appended instead of being inserted
at a specific index. Both operations have a complexity of O(1)`}<sup>{`*`}</sup>{` for
`}<inlineCode parentName="p">{`ArrayList`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`LinkedList`}</inlineCode>{` has a higher complexity of O(n) for random access via
`}<inlineCode parentName="p">{`List.get(n)`}</inlineCode>{`. But prepending elements has a lower complexity of O(1) for
`}<inlineCode parentName="p">{`LinkedList`}</inlineCode>{` compared to O(n) for `}<inlineCode parentName="p">{`ArrayList`}</inlineCode>{`. Same applies to the removal of
the first or last element in the list. Also, insertions and removals of elements
at a specific index are on average faster for `}<inlineCode parentName="p">{`LinkedList`}</inlineCode>{`.`}</p>
    <p>{`Therefore a `}<inlineCode parentName="p">{`LinkedList`}</inlineCode>{` is preferable when you rather have prepends, head/tail
removals or removals and insertions at a specific index, while you do not have a
lot of random access.`}</p>
    <sup>*</sup> amortized for appending, when resizing is not necessary

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      