import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What are static initializers and when would you use them?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A `}<inlineCode parentName="p">{`static`}</inlineCode>{` initializer gives you the opportunity to run code during the initial
loading of a class and it guarantees, that this code will only run once and will
finish before your class can be accessed in any way.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.util.Collections;
import java.util.HashMap;
import java.util.Map;

public class StaticInit {

  private static final Map<String, Boolean> FEATURE_FLAGS;

  static {
    final Map<String, Boolean> flags = new HashMap<>();
    flags.put("cool-feature", true);
    flags.put("lame-feature", false);
    FEATURE_FLAGS = Collections.unmodifiableMap(flags);
  }

  public static void main(String[] args) {
    // {lame-feature=false, cool-feature=true}
    System.out.println(FEATURE_FLAGS);
  }

}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      