import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "How does the Java memory model work?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The JVM divides memory between `}<strong parentName="p">{`thread stacks`}</strong>{` and the `}<strong parentName="p">{`heap`}</strong>{`.`}</p>
    <p>{`Each thread has its own thread stack. It contains the call stack, divided into
stack frames for each method, that has been called, to reach the current point
of execution. These stack frames store all local variables. A thread can only
access its own thread stack.`}</p>
    <p>{`The heap contains all Objects created in your Java application regardless of
what thread created the object, including objects of wrapper classes for
primitive times (e.g. `}<inlineCode parentName="p">{`Integer`}</inlineCode>{`, `}<inlineCode parentName="p">{`Double`}</inlineCode>{`, `}<inlineCode parentName="p">{`String`}</inlineCode>{`).`}</p>
    <p>{`All local variables of primitive types are fully stored on the thread stack and
are not visible to other threads. A local variable may also be a reference to an
object, then the reference is stored on the thread stack, but the object itself
is stored on the heap. An Object may contain methods that also contain local
variables, which are also stored on the thread stack, even if the object the
method belongs to is stored on the heap.`}</p>
    <p>{`An Object's member variables (fields) no matter if primitive type or object
reference are stored on the heap along with the object itself. Static class
variables are also stored on the heap along with the class definition. Objects
on the heap can be accessed by all threads, that have a reference to it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      