import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is the native keyword in Java? When is it used?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`native`}</inlineCode>{` keyword in java is applied to a method to indicate, that a method
is implemented in platform native code using JNI (Java Native Interface) / JNA
(Java Native Access). They are implemented in other languages, not in java. For
example, if you need to call a library from Java that is written in another
language or if you need to access system or hardware resources that are only
accessible from other languages (typically C).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      