import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is the synchronized keyword?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Synchronized blocks can only be executed by a single thread at a time. They can
thus be used to avoid `}<strong parentName="p">{`race conditions`}</strong>{`.`}</p>
    <p>{`Without the `}<inlineCode parentName="p">{`synchronized`}</inlineCode>{` keyword there are no guarantees about when a
variable, that is kept in a CPU register by one thread, is written to or
refreshed by reading from the main memory.`}</p>
    <p>{`By using the `}<inlineCode parentName="p">{`synchronized`}</inlineCode>{` keyword, all variables visible to the thread are
refreshed before entering the block and all changes to variables will be
committed back to the main memory when leaving the block.`}</p>
    <p>{`For more advanced locking semantics Read/Write Locks are used. There are `}<inlineCode parentName="p">{`Lock`}</inlineCode>{`
interface implementations, which can be used for reading and/or writing with an
optional fairness policy for the acquisition order enabled.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      