import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is a defender Method?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Defender Methods are `}<inlineCode parentName="p">{`default`}</inlineCode>{` methods, which were added in Java 8 to
interfaces. With them, it is possible to add new methods to interfaces without
breaking existing implementations by defining a `}<inlineCode parentName="p">{`default`}</inlineCode>{` behavior for all of
them.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      