import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is type erasure?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Generics are checked at compile-time for type-correctness. The generic type
information is removed in a process called type erasure. For example
`}<inlineCode parentName="p">{`List<Integer>`}</inlineCode>{` will be converted to the non-generic type `}<inlineCode parentName="p">{`List`}</inlineCode>{` containing
arbitrary `}<inlineCode parentName="p">{`Objects`}</inlineCode>{`. Because of that, type parameters cannot be determined at
run-time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      