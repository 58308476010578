import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is invariance, covariance and contravariance?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Java’s generic type parameters are invariant. This means for any distinct types
A and B, `}<inlineCode parentName="p">{`G<A>`}</inlineCode>{` is not a subtype or supertype of `}<inlineCode parentName="p">{`G<B>`}</inlineCode>{`. As a real world
example, `}<inlineCode parentName="p">{`List<Cat>`}</inlineCode>{` is not a supertype or subtype of `}<inlineCode parentName="p">{`List<Animal>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import java.util.List;

public class Invariance {

  public static void main(String[] args) {
    List<Animal> animals = List.of(new Cat(), new Dog());
    List<Cat> cats = List.of(new Cat());
    List<Dog> dogs = List.of(new Dog());

    print(animals); // works
    print(cats); // does not work
    print(dogs); // does not work
  }

  interface Animal {}

  static class Cat implements Animal {}
  static class Dog implements Animal {}

  static void print(List<Animal> animals) {
    animals.forEach(System.out::println);
  }

}
`}</code></pre>
    <p>{`To achieve covariance the wildcard operator combined with an `}<inlineCode parentName="p">{`extends`}</inlineCode>{` clause is
used. The type parameter `}<inlineCode parentName="p">{`T`}</inlineCode>{` is covariant in the generic type `}<inlineCode parentName="p">{`List<T>`}</inlineCode>{` when `}<inlineCode parentName="p">{`A`}</inlineCode>{`
is a subtype of `}<inlineCode parentName="p">{`B`}</inlineCode>{` and `}<inlineCode parentName="p">{`List<A>`}</inlineCode>{` is a subtype of `}<inlineCode parentName="p">{`List<B>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "{20}",
        "{20}": true
      }}>{`import java.util.List;

public class Covariance {

  public static void main(String[] args) {
    List<Animal> animals = List.of(new Cat(), new Dog());
    List<Cat> cats = List.of(new Cat());
    List<Dog> dogs = List.of(new Dog());

    print(animals); // works
    print(cats); // works
    print(dogs); // works
  }

  interface Animal {}

  static class Cat implements Animal {}
  static class Dog implements Animal {}

  static void print(List<? extends Animal> animals) {
    animals.forEach(System.out::println);
  }

}
`}</code></pre>
    <p>{`To achieve contravariance the wildcard operator combined with an `}<inlineCode parentName="p">{`super`}</inlineCode>{` clause
is used. The type parameter `}<inlineCode parentName="p">{`T`}</inlineCode>{` is contravariant in the generic type `}<inlineCode parentName="p">{`List<T>`}</inlineCode>{`
when `}<inlineCode parentName="p">{`A`}</inlineCode>{` is a subtype of `}<inlineCode parentName="p">{`B`}</inlineCode>{` and `}<inlineCode parentName="p">{`List<B>`}</inlineCode>{` is a subtype of `}<inlineCode parentName="p">{`List<A>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "{20}",
        "{20}": true
      }}>{`import java.util.List;

public class Contravariance {

  public static void main(String[] args) {
    List<Animal> animals = List.of(new Cat(), new Dog());
    List<Cat> cats = List.of(new Cat());
    List<Dog> dogs = List.of(new Dog());

    print(animals); // works
    print(cats); // works
    print(dogs); // does not work
  }

  interface Animal {}

  static class Cat implements Animal {}
  static class Dog implements Animal {}

  static void print(List<? super Cat> cats) {
    cats.forEach(System.out::println);
  }

}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      