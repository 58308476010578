import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "How to clone an object in Java?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To clone an object in java it is necessary to implement the `}<inlineCode parentName="p">{`Cloneable`}</inlineCode>{` marker
interface and to override the `}<inlineCode parentName="p">{`Object.clone()`}</inlineCode>{` method to make the `}<inlineCode parentName="p">{`protected`}</inlineCode>{`
clone method accessible. Inside the method the value of `}<inlineCode parentName="p">{`super.clone()`}</inlineCode>{` is
returned. By default java is doing a shallow copy of the object. That means all
fields of a primitive type are getting copied, but for objects only the
reference is copied. When a deep copy is created, all values of the object are
copied to a newly created object, regardless of how deeply nested they are.`}</p>
    <p>{`Shallow copy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "{22-25}",
        "{22-25}": true
      }}>{`public class ShallowCopy {

  public static void main(String[] args) throws CloneNotSupportedException {
    final Foo foo1 = new Foo("foo", new Bar("bar"));
    final Foo foo2 = foo1.clone();

    System.out.println(foo1.equals(foo2)); // false
    System.out.println(foo1.bar.equals(foo2.bar)); // true
    System.out.println(foo1.foo.equals(foo2.foo)); // true
    System.out.println(foo1.bar.bar.equals(foo2.bar.bar)); // true
  }

  private static class Foo implements Cloneable {
    private final String foo;
    private final Bar bar;

    private Foo(String foo, Bar bar) {
      this.foo = foo;
      this.bar = bar;
    }

    @Override
    protected Foo clone() throws CloneNotSupportedException {
      return (Foo) super.clone();
    }
  }

  private static class Bar {
    private final String bar;

    private Bar(String bar) {
      this.bar = bar;
    }
  }

}
`}</code></pre>
    <p>{`Deep copy:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "{22-27,37-40}",
        "{22-27,37-40}": true
      }}>{`public class DeepCopy {

  public static void main(String[] args) throws CloneNotSupportedException {
    final Foo foo1 = new Foo("foo", new Bar("bar"));
    final Foo foo2 = foo1.clone();

    System.out.println(foo1.equals(foo2)); // false
    System.out.println(foo1.bar.equals(foo2.bar)); // false
    System.out.println(foo1.foo.equals(foo2.foo)); // true
    System.out.println(foo1.bar.bar.equals(foo2.bar.bar)); // true
  }

  private static class Foo implements Cloneable {
    private final String foo;
    private Bar bar;

    private Foo(String foo, Bar bar) {
      this.foo = foo;
      this.bar = bar;
    }

    @Override
    protected Foo clone() throws CloneNotSupportedException {
      final Foo clone = (Foo) super.clone();
      clone.bar = clone.bar.clone();
      return clone;
    }
  }

  private static class Bar implements Cloneable {
    private final String bar;

    private Bar(String bar) {
      this.bar = bar;
    }

    @Override
    protected Bar clone() throws CloneNotSupportedException {
      return (Bar) super.clone();
    }
  }

}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      