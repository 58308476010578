import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is the JIT Compiler?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Just-In-Time Compiler is a component of the JVM runtime environment, that
improves the performance of java application by optimizing code and compiling
bytecodes to native machine code at run time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      