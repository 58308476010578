import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is the difference between an abstract class and an interface?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Abstract classes specify `}<strong parentName="p">{`what an object is`}</strong>{`, by defining characteristics of an
object type. They can have a constructor and can hold a state.`}</p>
    <p>{`Interfaces are used to establish a contract about `}<strong parentName="p">{`what an object can do`}</strong>{`.
They define capabilities that are promised to be provided by an implementing
object.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      