import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What are the different access modifiers available in Java?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There are four types of Access modifiers:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`public`}</inlineCode>{` – accessible from everywhere in the application`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`protected`}</inlineCode>{` – accessible within the package and the subclasses in any package`}</li>
      <li parentName="ul">{`package private (Default) – accessible strictly within the package`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`private`}</inlineCode>{` – accessible only within the same class where it is declared`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      