import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What are Generics in Java ? What are advantages of using Generics?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`They were designed to extend Java's type system to allow a type or method to
operate on objects of various types while providing compile-time type safety.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      