import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { DividerTitle } from "../../components/divider";
export const _frontmatter = {
  "question": "What do you know about the Big O notation?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`The Big O notation, also called Bachmann-Landau notation, `}<strong parentName="p">{`is a relative`}</strong>{`
`}<strong parentName="p">{`representation of the complexity of an algorithm`}</strong>{`.`}</p>
    <p>{`It is used for comparing algorithms according to how their run time or space
requirements grow as the input size grows. Big O always assumes the worst-case.
Regardless of the hardware, O(1) is always going to complete faster than O(n!).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "671px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.58333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAADVElEQVQ4y2NgYKhgZ2DIERfkYBDkZtZmEuVnEGBiZOBmQAB+DjZODhBDQdMcJsbCxMQMEmNkQAesTEU8DAzFinvClPh8JWKYtzQzCLgaMjDB5LODyvk15fXg/P///zPIa5qLAJminNzcLHBb+VhhBhYCDSyV+z/DmcOfeTrT42UMfP+/SYPlJhcuZl5YvZkXxC7Knw0Wy+zaxb/z639mZEexsDCyILkQZGCJ/P96e47lGXps1+Yy8MDkphYt5Z5csIgd7trefdw5ffu5EK6tZ1g4zRKkXgDEl5flhhlYLP+/1ot9V40M9435DGANRyfoggzkb6zfyAgxbD9bbt8efphh1074sF097iNw+4wfG4i/d6MzzIXl3BADPdh3VMlyAV0INnBmyVyOKcXLwJFT0L2bOa9/tyDMsKvHfXmBhvFeP+kL5rfW6CP8z8SgI8/AkKv4v92PfXuVDNd1qIHTShYK7AifxFi4dD0j0HVAw/4zvLpsxnr5mJ8AyHUw/VeO+YDph5cCGHJS1YAGMmorMDDkKfxv82A/2CIuCIxEht78bewzCxeBXZfcflwgtesoy+PzdtyXjvnz3TrlwQhxpQ8Yo7NBocPDylyo+L/Jnf14D6fgztnmjNMLl/FXN25ljGq9yLd4dTPXo3N2fOePBLMjvIww6BrCIBio5BLnzFf9P0mb69wCBq7ZBYt4WrK3cST3H+XbtjVf6NkFK54d23PBrrpy3BcUGbBIYcABirmE2YpV/8/i5d3ZYcJfnnyZt3n+ApH9u1JEP9/QhofVnoMhDAXrshgaN6Wi4Lp1SQxTzpYwuMSZMjAxg9N/CbcIe4Hqjwn8Iu3lsySnLJwsc2x3ND8D+aCTw00+Rv/K8ky1lVsnyf3/b8MBSbQpjEA2EGsAsRbT//+WQNoTiMOZz/yPY9v9P55j258EztVvYrku/S/lDCrUEGJiZpAEpTLW5ID0tOaOYmeTgAxpA7c8SevAXBmb4BwZC98caQu/AinnmBoJu7AiKauAHBlTz1wpbZs8cTmtfBFR2QIhcfkCIUmlYiFBiWRRRiZ1ToYg5QgmDY0CFaDBcizsRcA0WSwLxDIQXAHM1IlKDAxWFgwMQRoMDNVSwFwtC1WDhEFipXIMDLNZAAZ+GypVIfLZAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "big-o-chart",
          "title": "big-o-chart",
          "src": "/static/9971c9f2a57a8644e24ff1801163163b/508aa/big-o-chart.png",
          "srcSet": ["/static/9971c9f2a57a8644e24ff1801163163b/5243c/big-o-chart.png 240w", "/static/9971c9f2a57a8644e24ff1801163163b/ab158/big-o-chart.png 480w", "/static/9971c9f2a57a8644e24ff1801163163b/508aa/big-o-chart.png 671w"],
          "sizes": "(max-width: 671px) 100vw, 671px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <DividerTitle as="h4" variant="styles.h5" mdxType="DividerTitle">
  List
    </DividerTitle>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data structure`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Access`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Prepend`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Insert`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Append`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Delete`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Search`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Traverse`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ArrayList`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1) / O(n)`}<sup>{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LinkedList`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n/2)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`CopyOnWriteArrayList`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(2n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(2n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(2n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
      </tbody>
    </table>
    <sup>*</sup> when resizing is needed
    <DividerTitle as="h4" variant="styles.h5" mdxType="DividerTitle">
  Queue
    </DividerTitle>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data structure`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Peek`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Offer`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Poll`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LinkedList`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`PriorityQueue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ArrayDeque`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1) / O(n)`}<sup>{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ConcurrentLinkedQueue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ArrayBlockingQueue`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
        </tr>
      </tbody>
    </table>
    <sup>*</sup> when resizing is needed
    <DividerTitle as="h4" variant="styles.h5" mdxType="DividerTitle">
  Map
    </DividerTitle>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data structure`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Access`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Insert`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Delete`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Next`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Search`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HashMap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1) / O(n)`}<sup>{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(c/n)`}<sup>{`*`}{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(c+n)`}<sup>{`*`}{`*`}</sup></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LinkedHashMap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1) / O(n)`}<sup>{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TreeMap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n)`}</td>
        </tr>
      </tbody>
    </table>
    <sup>*</sup> when resizing is needed <br />
    <sup>**</sup> c is the table capacity
    <DividerTitle as="h4" variant="styles.h5" mdxType="DividerTitle">
  Set
    </DividerTitle>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Data structure`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Insert`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Delete`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Next`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Search`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Traverse`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`HashSet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1) / O(n)`}<sup>{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(c/n)`}<sup>{`*`}{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(c+n)`}<sup>{`*`}{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(c+n)`}<sup>{`*`}{`*`}</sup></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`LinkedHashSet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1) / O(n)`}<sup>{`*`}</sup></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`TreeSet`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(log(n))`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`O(n)`}</td>
        </tr>
      </tbody>
    </table>
    <sup>*</sup> when resizing is needed <br />
    <sup>**</sup> c is the table capacity

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      