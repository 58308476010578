import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is the volatile keyword? How and why would you use it?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<inlineCode parentName="p">{`volatile`}</inlineCode>{` modifier guarantees, that any thread that reads a field, will see
the `}<strong parentName="p">{`most recently written value`}</strong>{`.`}</p>
    <p>{`These variables are directly written to and read from the main memory instead of
the CPU cache. Reading and writing to main memory is more expensive.`}</p>
    <p>{`If two thread are reading and writing to a shared variable, where the new value
is generated based on the previous value (needs a read before writing), volatile
is not enough. The short time gap between reading and writing creates a race
condition, where multiple threads might read the same value and overwrite each
others value. In that case you need to use `}<inlineCode parentName="p">{`synchronized`}</inlineCode>{` keyword (or
`}<inlineCode parentName="p">{`AtomicReference`}</inlineCode>{`, `}<inlineCode parentName="p">{`AtomicInteger`}</inlineCode>{`, ...) to guarantee atomic reads and writes.`}</p>
    <p>{`One common use-case for using `}<inlineCode parentName="p">{`volatile`}</inlineCode>{` is for a flag to terminate a thread.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class ThreadWithVolatileFlag extends Thread {

  private volatile boolean close = false;

  public void run() {
    while(!close) {
      // do work
    }
  }

  public void close() {
    close = true;
    // interrupt here if needed
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      