import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { DividerTitle } from "../../components/divider";
export const _frontmatter = {
  "question": "Which language features have been added since Java 8? Can you name some?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  9
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/261"
        }}>{`JPMS - Java Module Platform System`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/213"
        }}>{`Private interface methods`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/213"
        }}>{`Allow try-with-resources on effectively `}<inlineCode parentName="a">{`final`}</inlineCode>{` variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/213"
        }}>{`Diamond operator for anonymous classes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/269"
        }}>{`Collection Factory Methods`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/266"
        }}>{`Reactive Streams Interfaces`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/264"
        }}>{`Platform logging`}</a></li>
    </ul>
    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  10
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/286"
        }}>{`Local-Variable Type Inference`}</a></li>
    </ul>
    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  11
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/321"
        }}>{`HTTP/2 Client`}</a></li>
    </ul>
    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  12
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/325"
        }}>{`Switch expressions`}</a></li>
    </ul>
    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  13
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/355"
        }}>{`Text blocks`}</a></li>
    </ul>
    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  14
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/359"
        }}>{`Records`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/358"
        }}>{`Helpful NullPointerExceptions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/305"
        }}>{`Pattern Matching for `}<inlineCode parentName="a">{`instanceof`}</inlineCode></a></li>
    </ul>
    <DividerTitle as="h4" variant="styles.h6" mdxType="DividerTitle">
  15
    </DividerTitle>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openjdk.java.net/jeps/360"
        }}>{`Sealed Types`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      