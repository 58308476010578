import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "question": "What is singleton class and how can you make a class singleton?"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`An implementation of the singleton pattern must:`}</p>
    <ul>
      <li parentName="ul">{`ensure that only one instance of the singleton class ever exists`}</li>
      <li parentName="ul">{`provide global access to that instance`}</li>
    </ul>
    <p>{`Typically, this is done by:`}</p>
    <ul>
      <li parentName="ul">{`declaring all constructors of the class to be private`}</li>
      <li parentName="ul">{`providing a static method that returns a reference to the instance`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class Singleton {

  private Singleton() {
    // initialization code
  }

  private static class SingletonHolder {
    public static final Singleton instance = new Singleton();
  }

  public static Singleton getInstance() {
    return SingletonHolder.instance;
  }

}
`}</code></pre>
    <p>{`The Bill Pugh singleton implementation from above, is the most widely used
implementation to provide a thread safe singleton. It uses an static inner class
for holding an instance of the enclosing class. This `}<inlineCode parentName="p">{`SingletonHolder`}</inlineCode>{` class is
not loaded until the `}<inlineCode parentName="p">{`getInstance()`}</inlineCode>{` method is called. Before the calling thread
is given access to it, the static instance is created as part of class loading.
This means safe lazy loading without any need for synchronization/locks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      